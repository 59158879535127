var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageTemplate',[_c('template',{slot:"nav"},[_c('BasicNav',[_c('template',{slot:"nav-left"},[_c('div',{staticClass:"flex justify-start"},[_c('BackButton',{on:{"click":_vm.goToAdminLog}})],1)]),_c('template',{slot:"nav-center"},[_c('div',{staticClass:"flex justify-center whitespace-nowrap"},[_c('h1',{staticClass:"text-3xl"},[_vm._v("Admin Access Event Log Detail")])])])],2)],1),_c('template',{slot:"body"},[_c('div',{staticClass:"grid grid-cols-1 mb-8"},[(_vm.errorFetching || _vm.isLoading)?_c('div',{staticClass:"m-auto"},[(_vm.isLoading)?_c('LoadingCard'):_vm._e(),(_vm.errorFetching)?_c('ErrorCard',{attrs:{"message":"Admin Access Detail Not Available"}}):_vm._e()],1):_c('div',[_c('div',{staticClass:" w-full"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.sessionDetail.totalElements,"max-height":"750px","theme":"polar-bear","sort-options":{
              enabled: false,
              },"pagination-options":{
                enabled: true,
                perPage: this.serverParams.size,
                perPageDropdownEnabled: false,
                dropdownAllowAll: false,
                setCurrentPage: _vm.sessionDetail.number + 1,
              },"rows":_vm.sessionDetail.content,"columns":_vm.columns,"styleClass":"vgt-table bordered"},on:{"on-page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{class:[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange  block p-0']},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('LoadingCard')],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No Details to show ")])])],1)])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }
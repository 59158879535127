<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-left">
          <div class="flex justify-start">
            <BackButton @click="goToAdminLog"></BackButton>
          </div>
        </template>
        <template slot="nav-center">
          <div class="flex justify-center whitespace-nowrap">
            <h1 class="text-3xl">Admin Access Event Log Detail</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="grid grid-cols-1 mb-8">
        <div class="m-auto"  v-if="errorFetching || isLoading">
          <LoadingCard v-if="isLoading"/>
          <ErrorCard v-if="errorFetching" message="Admin Access Detail Not Available"/>
        </div>
        <div v-else>
          <div class=" w-full">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                :totalRows="sessionDetail.totalElements"
                max-height="750px"
                theme="polar-bear"
                :sort-options="{
                enabled: false,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: this.serverParams.size,
                  perPageDropdownEnabled: false,
                  dropdownAllowAll: false,
                  setCurrentPage: sessionDetail.number + 1,
                }"
                :rows="sessionDetail.content"
                :columns="columns"
                styleClass="vgt-table bordered"
            >
              <template slot="table-column" slot-scope="props">
                 <span :class="[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange  block p-0']">
                {{props.column.label}}
                 </span>
              </template>
              <div slot="loadingContent">
                <LoadingCard/>
              </div>
              <div slot="emptystate">
                No Details to show
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import { VueGoodTable } from 'vue-good-table';
import {mapActions, mapState} from "vuex";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";

export default {
  name: "AdminAccessDetail",
  mixins: [LocalLogFormatters],
  components: {BackButton, LoadingCard, BasicNav, ErrorCard , PageTemplate, VueGoodTable},
  data() {
    return {
      isLoading: false,
      errorFetching: false,
      columns: [
        {
          label: 'Action',
          field: 'action',
          type: String,
        },
        {
          label: 'Date/Time',
          field: 'dateTime',
          sortable: false,
          formatFn: this.formatLogDate,
          type: String,
        },
      ],
      serverParams: {
        sort: 'dateTime,desc',
        page: 0,
        size: 8
      }
    }
  },
  computed: {
    ...mapState("GeneralSettings", ["sessionDetail"]),
  },
  methods: {
    ...mapActions("GeneralSettings", ['getUserAccessDetail']),
    updateParams(newProps) {
      this.serverParams = {...this.serverParams, ...newProps};
    },
    goToAdminLog() {
      this.$router.push({name: 'AdminAccessLogs'});
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage -1});
      this.fetchData();
    },
    fetchData() {
      this.isLoading = true;
      try{
        this.getUserAccessDetail({request:this.serverParams, sessionId: this.$route.params.sessionId});
      }catch (e) {
        this.errorFetching = true;
      }finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.fetchData()
  }
}
</script>
<style>
.chevron.right::after {
  border-left-color: rgba(245, 120, 18, 1) !important;
}
.chevron.left::after {
  border-right-color: rgba(245, 120, 18, 1) !important;
}
</style>